import { APP_BASE_HREF } from '@angular/common';
import { HttpBackend } from '@angular/common/http';
import { inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { CoreModule } from 'projects/core/src/lib/core.module';
import { APP_NAME } from 'projects/core/src/lib/injection-tokens';
import { AppName } from 'projects/core/src/lib/models/app.model';
import { SharedModule } from 'projects/shared/src/lib/shared.module';
import { ThemeModule } from 'projects/theme/src/lib/theme.module';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  const basePath: string = inject(APP_BASE_HREF);
  return new MultiTranslateHttpLoader(_httpBackend, [
    `${basePath}shared/assets/i18n/`,
    `${basePath}assets/i18n/`,
  ]);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    SharedModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    { provide: APP_NAME, useValue: AppName.KP },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
